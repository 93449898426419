import React from 'react'
import {
  ArrayField,
  BooleanField,
  Datagrid,
  DateField,
  FileField,
  FunctionField,
  NumberField,
  ReferenceField,
  RichTextField,
  SelectField,
  Show,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TextField,
  useShowController,
} from 'react-admin'

import { OrderTypeTranslated } from '../../config/components'
import customerPositionings from './CustomerPositionings'
import { convertSecondsToHoursMinutes, displayDateTime, displayMonthYear } from '../../helpers/DateHelpers'
import AcceptPonctuelPurchaseModal from './components/AcceptPonctuelPurchaseModal'
import ConfirmRenewalOfRecurringPurchase from './components/ConfirmRenewalOfRecurringPurchase'
import CancelRecurringPurchase from './components/CancelRecurringPurchase'

const PurchaseShow = (props) => {
  const purchase = useShowController(props).record
  const recurring = purchase && purchase.recurring
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Informations">
          <SimpleShowLayout>
            <TextField source="id" label="# devis" />
            <TextField source="status" label="Statut" />
            <TextField source="serviceLevel" label="Offre" />
            <ReferenceField label="# demande" link="show" source="purchaseRequest" reference="purchaserequests">
              <FunctionField render={(record) => `${record.id} (${record.status} - ${record.serviceLevel})`} />
            </ReferenceField>
            <DateField locales="fr-FR" source="createdDate" label="Date" />
            <ReferenceField label="Vendeur" link="show" source="sellerEntityRelation" reference="professionals">
              <FunctionField
                render={(record) => `${record.profile.lastName} ${record.profile.firstName} - ${record.professional.company.name}`}
              />
            </ReferenceField>
            <ReferenceField label="Acheteur" link="show" source="buyerEntityRelation" reference="customers">
              <FunctionField render={(record) => `${record.profile.lastName} ${record.profile.firstName}`} />
            </ReferenceField>
            <NumberField source="priceTTC" label="Devis TTC" options={{ style: 'currency', currency: 'EUR' }} />
            <NumberField source="priceHT" label="Devis HT" options={{ style: 'currency', currency: 'EUR' }} />
            <NumberField source="sellerFees" label="Taux vendeur" />
            <NumberField source="buyerFees" label="Taux acheteur" />
            <FileField source="quote.path" target="_blank" title="Devis" label="Devis" />
            <SelectField
              label="Position du client"
              source="customerPositioning"
              choices={customerPositionings}
              optionText="value"
              optionValue="id"
            />
            {recurring && <BooleanField source="shouldBeRenewed" label="Activation du renouvellement automatique" />}
            <AcceptPonctuelPurchaseModal />
            <ReferenceField
              label="Devis renouvellé"
              link="show"
              source="purchaseIdOfRenewedPurchase"
              reference="purchases"
            >
              <FunctionField render={(record) => `${record.id}`} />
            </ReferenceField>
            <ConfirmRenewalOfRecurringPurchase />
            <CancelRecurringPurchase />
          </SimpleShowLayout>
        </Tab>
        <Tab label="Vendeur">
          <TextField source="sellerInformations.lastName" label="Nom" />
          <TextField source="sellerInformations.firstName" label="Prénom" />
          <TextField source="sellerInformations.companyName" label="Société" />
          <TextField source="sellerInformations.companySiret" label="SIRET" />
          <BooleanField source="sellerInformations.companyHasAgreement" label="SAP" />
          <BooleanField source="sellerInformations.companySubjectToVat" label="TVA" />
          <BooleanField source="sellerInformations.professionalAtjCollaborator" label="Collaborateur ATJ" />
          <NumberField source="sellerFees" label="Taux" />
        </Tab>
        <Tab label="Acheteur">
          <TextField source="buyerInformations.lastName" label="Nom" />
          <TextField source="buyerInformations.firstName" label="Prénom" />
          <NumberField source="buyerFees" label="Taux" />
        </Tab>
        <Tab label="Interventions">
          <SimpleShowLayout>
            <ArrayField source="appointments" label="Dates d'interventions">
              <Datagrid>
                <FunctionField
                  label="Date"
                  render={(record) => {
                    if (record.monthly) return displayMonthYear(new Date(record.appointmentDate))
                    return displayDateTime(new Date(record.appointmentDate))
                  }}
                />
                <TextField source="status" label="Description" />
                <FunctionField
                  label="Temps de l'intervention"
                  render={(record) => {
                    if (record.appointmentDurationInSeconds !== undefined) return `${convertSecondsToHoursMinutes(record.appointmentDurationInSeconds)}`
                    return ''
                  }}
                />
                <TextField source="proComments" label="Commentaires du pro" />
              </Datagrid>
            </ArrayField>
          </SimpleShowLayout>
        </Tab>
        <Tab label="Produits">
          <SimpleShowLayout>
            <ArrayField source="products">
              <Datagrid>
                <RichTextField source="description" label="Description" />
                <NumberField source="quantity" label="Quantité" />
                <NumberField
                  source="unitPrice"
                  label="Prix unitaire"
                  options={{ style: 'currency', currency: 'EUR' }}
                />
                <NumberField source="taxRate" label="Taux TVA" />
              </Datagrid>
            </ArrayField>
          </SimpleShowLayout>
        </Tab>
        <Tab label="Echéances">
          <SimpleShowLayout>
            <ArrayField source="orders" fieldKey="id" label="Echéances de paiements">
              <Datagrid rowClick={(id, resource, record) => `/purchasematurity/${record.maturity.id}/show`}>
                <DateField locales="fr-FR" source="maturity.dueDate" label="Date d'échéance" />
                <OrderTypeTranslated source="type" label="Type" />
                <NumberField source="maturity.number" label="# échéance" />
                <NumberField source="priceTTC" label="Prix TTC" options={{ style: 'currency', currency: 'EUR' }} />
                <NumberField source="priceHT" label="Prix HT" options={{ style: 'currency', currency: 'EUR' }} />
                <NumberField source="amountPaid" label="Payé" options={{ style: 'currency', currency: 'EUR' }} />
                <TextField source="paymentStatus" label="Statut paiement" />
                <TextField source="paymentType" label="Type paiement" />
                <TextField source="depositPaymentStatus" label="Statut Avance" />
                <TextField source="moneyOutPaymentStatus" label="Statut Reversement" />
                <FileField source="invoiceFile.path" title="invoiceNumber" target="_blank" label="Facture" />
                <TextField source="invoiceNumber" title="invoiceNumber" label="Facture&nbsp;#" />
              </Datagrid>
            </ArrayField>
          </SimpleShowLayout>
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

export default PurchaseShow
