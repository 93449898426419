import React from 'react'
import {
  ArrayField,
  BooleanField,
  Datagrid,
  DateField,
  FileField,
  FunctionField,
  NumberField,
  ReferenceField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  useShowController,
} from 'react-admin'
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from '@material-ui/core'
import getPaymentStatus from './GetPaymentStatusTranslation'
import { displayPriceInEuros } from '../../../helpers/PriceHelpers'
import FieldTitleShow from '../../../components/FieldTitleShow'
import CashInModalViewOffre1bis from './CashInModalViewOffre1bis'
import GenerateCreditNote1bisModalView from './GenerateCreditNote1bisModalView'
import CashOutModalViewOffre1bis from './CashOutModalViewOffre1bis'

const renderCreditNote = (creditNote) => {
  if (creditNote) {
    switch (creditNote.status) {
      case 'CREATED':
        return (
          <FileField
            label="Avoir Pro"
            source="creditNoteFile.path"
            title="number"
            target="_blank"
            record={creditNote}
          />
        )
      case 'CREATION_REQUESTED':
        return 'En attente de validation'
      case 'CREATED_BY_ACCOUNTING_DPT':
        return 'Créé par le service comptable'
      default:
        return null
    }
  }
  return null
}

const Offre1bisInvoiceShow = (props) => {
  const { record } = useShowController(props)
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Informations">
          <FileField label="Facture Client" source="invoiceFile.path" title="number" target="_blank" />
          <FileField label="Facture Pro" source="proATJInvoiceFile.path" title="proATJInvoiceNumber" target="_blank" />
          <ReferenceField label="Acheteur" link="show" source="buyer.id" reference="customers1bis">
            <FunctionField render={(record) => `${record.last_name} ${record.first_name}`} />
          </ReferenceField>
          <ReferenceField label="Vendeur" link="show" source="seller.profileEntityRelationId" reference="professionals">
            <FunctionField render={(record) => `${record.profile.lastName} ${record.profile.firstName}`} />
          </ReferenceField>
          <NumberField
            source="finalPriceTTC"
            label="Facture TTC"
            options={{
              style: 'currency',
              currency: 'EUR',
            }}
          />
          <NumberField
            source="proATJInvoicePriceTTC"
            label="Montant Reversement Pro"
            options={{
              style: 'currency',
              currency: 'EUR',
            }}
          />
          <DateField locales="fr-FR" source="creationDate" label="Date de Création" />
          <DateField locales="fr-FR" source="paymentLimitDate" label="Date Limite de Paiement" />
          <FunctionField
            label="Avoir Client"
            render={(record) => renderCreditNote(record.creditNote) || 'Non généré'}
          />
          <FunctionField
            label="Avoir Pro"
            render={(record) => renderCreditNote(record.proCreditNote) || 'Non généré'}
          />
          <div
            style={{
              paddingBottom: '16px',
            }}
          >
            <GenerateCreditNote1bisModalView record={record} />
          </div>
        </Tab>
        <Tab label="Intervenants">
          <ArrayField source="gardeners" label="Intervenants">
            <Datagrid>
              <TextField source="sageId" label="SAGE ID" />
              <TextField source="firstName" label="Prénom" />
              <TextField source="lastName" label="Nom" />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label="Paiements">
          <NumberField
            source="finalPriceTTC"
            label="Facture TTC"
            options={{
              style: 'currency',
              currency: 'EUR',
            }}
          />
          <FieldTitleShow title="Méthodes de paiement" />
          <TableContainer style={{ marginBottom: '32px' }}>
            <Table aria-label="payment methods table">
              <TableHead>
                <TableRow>
                  <TableCell>Carte Bleue</TableCell>
                  <TableCell>Virement</TableCell>
                  <TableCell>Virtual IBAN</TableCell>
                  <TableCell>Chèque</TableCell>
                  <TableCell>CESU</TableCell>
                  <TableCell>Pertes et profits</TableCell>
                  <TableCell>Wallet</TableCell>
                  <TableCell>Avance Immediate</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    {record
                      && record.customerPaymentDetails
                      && displayPriceInEuros(record.customerPaymentDetails.creditCard)}
                  </TableCell>
                  <TableCell>
                    {record
                      && record.customerPaymentDetails
                      && displayPriceInEuros(record.customerPaymentDetails.bankTransfer)}
                  </TableCell>
                  <TableCell>
                    {record
                      && record.customerPaymentDetails
                      && displayPriceInEuros(record.customerPaymentDetails.virtualIban)}
                  </TableCell>
                  <TableCell>
                    {record
                      && record.customerPaymentDetails
                      && displayPriceInEuros(record.customerPaymentDetails.cheque)}
                  </TableCell>
                  <TableCell>
                    {record && record.customerPaymentDetails && displayPriceInEuros(record.customerPaymentDetails.cesu)}
                  </TableCell>
                  <TableCell>
                    {record
                      && record.customerPaymentDetails
                      && displayPriceInEuros(record.customerPaymentDetails.lossAndProfit)}
                  </TableCell>
                  <TableCell>
                    {record
                      && record.customerPaymentDetails
                      && displayPriceInEuros(record.customerPaymentDetails.wallet)}
                  </TableCell>
                  <TableCell>
                    {record
                      && record.customerPaymentDetails
                      && displayPriceInEuros(record.customerPaymentDetails.avanceImmediate)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <NumberField
            source="proATJInvoicePriceTTC"
            label="Montant Reversement Pro"
            options={{
              style: 'currency',
              currency: 'EUR',
            }}
          />
          <FunctionField
            label="Statut Paiement Client"
            render={(record) => getPaymentStatus(record.customerPaymentStatus)}
          />
          <BooleanField
            label="Chèque ou CESU du client reçu (en cours de traitement)"
            source="clientPaymentChequeOrCesuProcessing"
          />
          <FunctionField
            label="Statut Reversement Pro"
            render={(record) => getPaymentStatus(record.proPaymentStatus)}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              paddingBottom: '16px',
              paddingTop: '16px',
            }}
          >
            <CashInModalViewOffre1bis record={record} />
            <CashOutModalViewOffre1bis record={record} />
          </div>
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

export default Offre1bisInvoiceShow
