import React from 'react'
import {
  ArrayField,
  BooleanField,
  Datagrid,
  DateField,
  FileField,
  FunctionField,
  NumberField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TextField,
  Button,
  DeleteButton,
  Pagination,
  ReferenceManyField,
  useRefresh,
} from 'react-admin'
import Divider from '@material-ui/core/Divider'
import AddCircle from '@material-ui/icons/AddCircle'
import { dateAndTimeWithWeekday } from '../../helpers/dateRender'
import { IDENTIFIERS as RESOURCE_IDENTIFIERS } from '../../config/resources'
import CashInModalView from './CashInModalView'
import { OrderTypeTranslated } from '../../config/components'
import dataProvider from '../../helpers/dataProvider'
import { convertSecondsToHoursMinutes, displayDateTime, displayMonthYear } from '../../helpers/DateHelpers'
import CashOutModalView from '../professionals/CashOutModalView'
import { purchaseBusinessLineType, purchaseRequestStatus } from '../../enums/Purchase'

const AddPurchaseProfessionalRequestButton = ({ record }) => record.status === 'PR_VISITS_ONGOING' || record.status === 'PR_QUOTES_ONGOING' ? (
  <Button
    label="Créer une nouvelle demande pour un professionnel"
    onClick={() => {
      window.location.href = `/#/${
        RESOURCE_IDENTIFIERS.PURCHASE_REQUEST_PROFESSIONAL
      }/create?source=${JSON.stringify({ purchaseRequestId: record.id })}`
    }}
  >
    <AddCircle />
  </Button>
) : null

const DeleteProfessionalPurchaseRequestButton = ({ record, ...rest }) => record?.deletable === true ? <DeleteButton record={record} redirect="1" undoable={false} /> : null

const StopRequestButton = ({ record, ...rest }) => {
  const refresh = useRefresh()
  const handleStopContract = () => {
    dataProvider.post(`/purchaserequests/${record.id}/stop`).then((response) => {
      if (response.status === 200) {
        refresh()
      }
    })
  }
  if (record.businessLine && record.businessLine === 'REGULIER' && record.status && record.status === 'PR_ACCEPTED') {
    return (
      <>
        <Button onClick={handleStopContract} label="Stopper le contrat" />
        <Divider />
      </>
    )
  }
  return <></>
}

const prpRowStyleBlocked = (record, index) => ({
  backgroundColor: record.blockedReason ? '#ffe0e0' : 'inherit',
})

const getTimeLeftInSeconds = (record) => {
  if (record.businessLine === 'REGULIER') {
    return record.timeLeftInSeconds
  }
  if (record.appointments.find((appointment) => appointment.status === 'DONE')) {
    return 0
  }
  return record.timeInterventionsInSeconds
}

const shouldDisplayCashOutButton = (record) => {
  const isCreationPurchaseFinished = record.businessLineType === purchaseBusinessLineType.CREATION
    && record.purchaseRequestStatus === purchaseRequestStatus.FINISHED
  const isElagagePurchaseFinished = record.businessLineType === purchaseBusinessLineType.ELAGAGE
    && record.purchaseRequestStatus === purchaseRequestStatus.FINISHED
  const isPunctualPurchasedFinished = record.businessLineType === purchaseBusinessLineType.PUNCTUAL
    && record.purchaseRequestStatus === purchaseRequestStatus.FINISHED
  const isRecurringPurchaseAccepted = record.businessLineType === purchaseBusinessLineType.RECURRING
    && record.purchaseRequestStatus === purchaseRequestStatus.ACCEPTED
  const isRecurringPurchaseFinished = record.businessLineType === purchaseBusinessLineType.RECURRING
    && record.purchaseRequestStatus === purchaseRequestStatus.FINISHED
  return (
    (record.proPaymentStatus === 'PAYMENT_ERROR'
      || record.proMoneyOutStatus === 'PAYMENT_ERROR'
      || record.proPaymentInError === true)
    && (isPunctualPurchasedFinished
      || isRecurringPurchaseAccepted
      || isRecurringPurchaseFinished
      || isCreationPurchaseFinished
      || isElagagePurchaseFinished)
  )
}

const PurchaseRequestShow = (props) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="Informations demande">
        <TextField source="id" label="Identifiant" />
        <ReferenceField label="Nom" link="show" source="customerEntityRelationId" reference="customers">
          <TextField source="profile.lastName" />
        </ReferenceField>
        <ReferenceField label="Prénom" link="show" source="customerEntityRelationId" reference="customers">
          <TextField source="profile.firstName" />
        </ReferenceField>
        <TextField source="businessLine" label="Business line" />
        <TextField source="serviceLevel" label="Offre" />
        <TextField source="origin" label="Origine" />
        <TextField source="status" label="État" />
        <ReferenceField
          link="show"
          label="Code avantage"
          source="appliedCouponCodeId"
          reference={RESOURCE_IDENTIFIERS.COUPON_CODES}
        >
          <TextField source="name" />
        </ReferenceField>
        <BooleanField source="visitRequired" label="Avec visite" />
        <DateField locales="fr-FR" source="createdDate" label="Date de création" />
        <TextField source="address.streetAndNumber" label="N° et rue" />
        <TextField source="address.zipCode" label="Code postal" />
        <TextField source="address.city" label="Ville" />
        <Divider />
        <TextField source="description" label="Description" />
        <Divider />
        <StopRequestButton />
        <ArrayField source="disponibilities" label="Disponibilités">
          <Datagrid>
            <DateField locales="fr-FR" options={dateAndTimeWithWeekday} source="from" label="Du" />
            <DateField locales="fr-FR" options={dateAndTimeWithWeekday} source="to" label="Au" />
          </Datagrid>
        </ArrayField>
      </Tab>
      <Tab label="Réponses">
        <BooleanField source="onDemandProspecting" label="Prospection à la demande" />
        <AddPurchaseProfessionalRequestButton />
        <ReferenceManyField
          reference={RESOURCE_IDENTIFIERS.PURCHASE_REQUEST_PROFESSIONAL}
          sort={{
            field: 'createdDate',
            order: 'DESC',
          }}
          target="purchaseRequest"
          addLabel={false}
          pagination={<Pagination />}
        >
          <Datagrid rowStyle={prpRowStyleBlocked}>
            <TextField source="status" label="Statut" />
            <ReferenceField label="Vendeur" link="show" source="professionalEntityRelationId" reference="professionals">
              <FunctionField
                render={(record) => `${record.profile.lastName} ${record.profile.firstName} - ${record.professional.company.name}`}
              />
            </ReferenceField>
            <FunctionField
              label="Date de la vue"
              render={(record) => {
                if (record.seenDate) return displayDateTime(new Date(record.seenDate))
                return <p style={{ fontStyle: 'italic' }}>Non vu</p>
              }}
            />
            <BooleanField source="smsReminderSent" label="SMS réactivité" />
            <BooleanField source="visitDone" label="Visite effectuée" />
            <DateField locales="fr-FR" source="visitDate" label="Date de la visite" />
            <TextField source="blockedReason" label="Bloqué" />
            <TextField source="cancellationReason" label="Annulation" />
            <TextField source="cancellationBy" label="Annulé par" />
            <ReferenceField label="Devis" link="show" source="purchase.id" reference="purchases">
              <TextField source="status" label="Annulé par" />
            </ReferenceField>
            <TextField source="purchase.serviceLevel" label="Offre" />
            <ReferenceField label="Devis €" link="show" source="purchase.id" reference="purchases">
              <NumberField
                source="priceTTC"
                label="Prix TTC"
                options={{
                  style: 'currency',
                  currency: 'EUR',
                }}
              />
            </ReferenceField>
            <FileField source="purchase.quote.path" title="Devis" target="_blank" label="Devis" />
            <DeleteProfessionalPurchaseRequestButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Factures">
        <SimpleShowLayout>
          <ArrayField source="orders" fieldKey="id" label="Echéances de paiements">
            <Datagrid rowClick={(id, resource, record) => `/purchasematurity/${record.maturity.id}/show`}>
              <DateField locales="fr-FR" source="maturity.dueDate" label="Date d'échéance" />
              <OrderTypeTranslated source="type" label="Type" />
              <NumberField source="maturity.number" label="# échéance" />
              <NumberField
                source="priceTTC"
                label="Prix TTC"
                options={{
                  style: 'currency',
                  currency: 'EUR',
                }}
              />
              <NumberField
                source="priceHT"
                label="Prix HT"
                options={{
                  style: 'currency',
                  currency: 'EUR',
                }}
              />
              <NumberField
                source="amountPaid"
                label="Payé"
                options={{
                  style: 'currency',
                  currency: 'EUR',
                }}
              />
              <TextField source="paymentStatus" label="Statut paiement" />
              <TextField source="paymentType" label="Type paiement" />
              <TextField source="depositPaymentStatus" label="Statut Avance" />
              <TextField source="moneyOutPaymentStatus" label="Statut Reversement" />
              <FileField source="invoiceFile.path" target="_blank" title="invoiceNumber" label="Facture" />
              <TextField source="invoiceNumber" title="invoiceNumber" label="Facture&nbsp;#" />
              <FileField source="notes" src="path" title="filename" label="Note de paiement" target="_blank" />
              <FileField
                source="reimbursedInvoiceFile.path"
                target="_blank"
                title="reimbursedInvoiceNumber"
                label="Old Facture"
              />
              <TextField source="reimbursedInvoiceNumber" label="Old Facture&nbsp;#" />
              <FileField
                source="reimbursementInvoiceFile.path"
                target="_blank"
                title="reimbursementInvoiceNumber"
                label="Avoir"
              />
              <TextField source="reimbursementInvoiceNumber" title="reimbursementInvoiceNumber" label="Avoir&nbsp;#" />
            </Datagrid>
          </ArrayField>
        </SimpleShowLayout>
      </Tab>
      <Tab label="Encaissements">
        <SimpleShowLayout>
          <ArrayField source="purchaseMaturities" fieldKey="id" label="Echéances de paiements">
            <Datagrid>
              <ReferenceField label="Devis" link="show" source="purchase" reference="purchases">
                <TextField source="status" />
              </ReferenceField>

              <DateField locales="fr-FR" source="maturityDueDate" label="Date d'échéance" />
              <TextField source="businessLineType" label="Business Line" />

              <NumberField
                source="quoteCustomerTTC"
                label="Devis TTC"
                options={{ style: 'currency', currency: 'EUR' }}
              />
              <NumberField
                source="paymentCustomerTTC"
                label="Paiment Client"
                options={{ style: 'currency', currency: 'EUR' }}
              />
              <TextField source="customerPaymentType" label="Paiement Client" />
              <TextField source="customerDepositStatus" label="Statut Avance Client" />
              <TextField source="customerPaymentStatus" label="Statut Paiement Client" />

              <NumberField
                source="proPaymentAmount"
                label="Reversement pro"
                options={{ style: 'currency', currency: 'EUR' }}
              />
              <TextField source="proMoneyOutStatus" label="Statut Reversement" />

              <CashInModalView />
              <FunctionField
                render={(record) => (
                  <CashOutModalView enabled={shouldDisplayCashOutButton(record)} purchaseMaturityId={record.id} />
                )}
              />
            </Datagrid>
          </ArrayField>
        </SimpleShowLayout>
      </Tab>
      <Tab label="Détails intervention">
        <FunctionField
          label="Temps restant sur cette demande"
          render={(record) => {
            const formattedTimeInterventions = record.timeInterventionsInSeconds
              ? `${convertSecondsToHoursMinutes(record.timeInterventionsInSeconds)}`
              : '-'
            const timeLeftInSeconds = getTimeLeftInSeconds(record)
            const formattedTimeLeft = timeLeftInSeconds !== undefined ? `${convertSecondsToHoursMinutes(timeLeftInSeconds)}` : '-'
            return `${formattedTimeLeft} / ${formattedTimeInterventions}`
          }}
        />
        <SimpleShowLayout>
          <ArrayField source="appointments" fieldKey="id" label="Interventions">
            <Datagrid>
              <FunctionField
                label="Date"
                render={(record) => {
                  if (record.monthly) return displayMonthYear(new Date(record.appointmentDate))
                  return displayDateTime(new Date(record.appointmentDate))
                }}
              />
              <FunctionField
                label="Temps de l'intervention"
                render={(record) => {
                  if (record.appointmentDuration !== undefined) return `${convertSecondsToHoursMinutes(record.appointmentDuration)}`
                  return ''
                }}
              />
              <TextField source="proComments" label="Commentaires du pro" />
            </Datagrid>
          </ArrayField>
        </SimpleShowLayout>
      </Tab>
    </TabbedShowLayout>
  </Show>
)

export default PurchaseRequestShow
