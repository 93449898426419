import React, { useState } from 'react'
import { Button, useNotify, useRefresh } from 'react-admin'

import IconCancel from '@material-ui/icons/Cancel'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'

import DialogContentText from '@material-ui/core/DialogContentText'
import { Cached } from '@material-ui/icons'
import dataProvider from '../../../helpers/dataProvider'

const ConfirmRenewalOfRecurringPurchase = ({ record }) => {
  const [showDialog, setShowDialog] = useState(false)
  const refresh = useRefresh()
  const notify = useNotify()
  const [loading, setLoading] = React.useState(false)

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  const handleConfirm = () => {
    const url = `/purchases/${record.id}/renew-recurring`
    setLoading(true)
    dataProvider
      .post(url)
      .then(() => {
        setLoading(false)
        notify('Le devis de renouvellement du contrat a bien été créé', 'info')
        refresh()
      })
      .catch((error) => {
        if (
          error.response
          && error.response.data
          && error.response.data
          && error.response.data.error === 'PURCHASE_ALREADY_RENEWED'
        ) {
          notify('Erreur: le devis de renouvellement de ce contrat a déjà été créé', 'error')
        } else {
          notify("Erreur inconnue: le devis de renouvellement n'a pas pu être créé", 'error')
        }
        setLoading(false)
      })
  }

  const needToShowButton = () => record.recurring === true && ['P_FINISHED', 'P_ACCEPTED', 'P_STOPPED'].includes(record.status)

  const renewalDialogView = () => (
    <Dialog open={showDialog}>
      <DialogTitle>Renouveler le contrat sur l'année suivante ?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <b>&emsp;Attention ! Une fois confirmé, le devis de renouvellement sera</b>
        </DialogContentText>
        <DialogContentText>
          <b>&emsp;créé et les mails du processus de renouvellement seront envoyés.</b>
        </DialogContentText>
        <DialogContentText>&emsp;Cette action permet d'enclencher le processus de renouvellement</DialogContentText>
        <DialogContentText>&emsp;en dehors du processus de renouvellement automatique habituel.</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm} label="Confirmer" disabled={loading}>
          <ThumbUpIcon />
        </Button>
        <Button onClick={handleCloseClick} label="Annuler" disabled={loading}>
          <IconCancel />
        </Button>
      </DialogActions>
    </Dialog>
  )

  const result = () => {
    const label = 'Demander le renouvellement du contrat'
    if (needToShowButton()) {
      return (
        <>
          <Button onClick={handleClick} label={label} disabled={loading}>
            <Cached />
          </Button>
          {renewalDialogView(label)}
        </>
      )
    }
    return <></>
  }

  return result()
}

export default ConfirmRenewalOfRecurringPurchase
