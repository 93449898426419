import React, { useEffect, useState } from 'react'
import { Button } from 'react-admin'
import IconCancel from '@material-ui/icons/Cancel'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import { FormControlLabel, Switch } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import DialogContent from '@material-ui/core/DialogContent'
import dataProvider from '../../helpers/dataProvider'

const CashOutModalView = ({ enabled, purchaseMaturityId }) => {
  const [showDialog, setShowDialog] = useState(false)
  const [transactionAlreadyDoneOutsideSystem, setTransactionAlreadyDoneOutsideSystem] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {}, [])

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  const handleCashOut = () => {
    setLoading(true)
    dataProvider
      .post(`/cashout/${purchaseMaturityId}?already_done_outside_system=${transactionAlreadyDoneOutsideSystem}`, {})
      .then(setShowDialog(false))
      .finally(() => {
        setLoading(false)
        window.location.reload()
      })
  }

  const cashOutDialogView = () => (
    <Dialog fullWidth open={showDialog}>
      <DialogTitle>Reverser le paiement du professionel</DialogTitle>
      <DialogContent>
        <FormControlLabel
          control={(
            <Switch
              checked={transactionAlreadyDoneOutsideSystem}
              onChange={(e) => {
                setTransactionAlreadyDoneOutsideSystem(e.target.checked)
              }}
            />
          )}
          label="Transaction déjà effectuée en dehors du système ?"
          labelPlacement="start"
        />

        {transactionAlreadyDoneOutsideSystem ? (
          <Typography variant="body1" color="error">
            Attention : vous indiquez ici que la transaction à déjà été envoyée au pro en dehors du système, aucun
            virement ne sera effectué vers son compte
          </Typography>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCashOut} label="Confirmer" disabled={loading}>
          <ThumbUpIcon />
        </Button>
        <Button onClick={handleCloseClick} label="Annuler" disabled={loading}>
          <IconCancel />
        </Button>
      </DialogActions>
    </Dialog>
  )

  const result = () => {
    const label = 'Encaisser pro'
    if (enabled) {
      return (
        <>
          <Button onClick={handleClick} label={label}>
            <AttachMoneyIcon />
          </Button>
          {cashOutDialogView()}
        </>
      )
    }
    return <></>
  }

  return result()
}

export default CashOutModalView
